import React from 'react'
import { Layout, Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import './Footer.css'

export default function Footer() {

	const slogan = "©️ 2024 Coin-Ranks.com"

	return (
		<Layout.Footer>
			<section className='footer-container'>
			<Row justify="start" gutter={[16, 32]} className='footer-container'>

				<Col lg={8} md={8}>
					<span className='blog-button-text'>
						{slogan}
					</span>
				</Col>

				<Col span={4} className='footer-column'>
					<h2>Resources</h2>
					<div className='footer-item'>
						<Link to='/blog/recent/benefits-of-coin-ranks.html' target="_blank" rel="noopener noreferrer">
							Manifesto
						</Link>
					</div>
					<div className='footer-item'>
						<Link to='/blog/' target="_blank" rel="noopener noreferrer">
							Blog
						</Link>
					</div>
				</Col>
			</Row>
			</section>
		</Layout.Footer>
	)
}