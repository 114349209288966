import React, { createContext, useContext, useState } from 'react';

const DataContext = createContext();

export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};

export const DataProvider = ({ children }) => {
  const [daysBack, setDaysBack] = useState(7);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousDaysBack, setPreviousDaysBack] = useState(null);

  return (
    <DataContext.Provider value={{
        daysBack, setDaysBack,
        previousDaysBack, setPreviousDaysBack,
        data, setData,
        currentPage, setCurrentPage,
      }}>
      {children}
    </DataContext.Provider>
  );
};
