import { Radio, Grid  } from 'antd'
const { useBreakpoint } = Grid;

export default function RangeSelector(props) {

    const screens = useBreakpoint();
    const options = [
		{ label: '48h', value: 2 },
		{ label: '7d', value: 7 },
		{ label: '14d', value: 14 },
		{ label: '1m', value: 30 },
		{ label: '3m', value: 90 },
        { label: '6m', value: 180 },
        { label: '1yr', value: 365 },
	];

    // React render
    return (
        <Radio.Group
            size={radioSize(screens)}
            options={options}
            onChange={props.onChange}
            optionType="button"
            value={props.value}
			style={props.style}
        />
	)
}

function radioSize(screens) {
	if (screens.md) {
		return 'large'
	}
	else if (screens.sm) {
		return 'medium'
	}
	else {
		return 'small'
	}
}
