import App from './App.js';
import Home from './pages/home/index.js';
import CoinDetails from './pages/coin/index.js';

export const routes = [
  {
    path: "/",
    Component: App,
    children: [
      { path: "/", Component: Home },
      { path: "coin/:id", Component: CoinDetails }
    ],
  },
];
