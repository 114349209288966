import axios from 'axios';
import { API_URL } from '../config/config.js'

class CoinRanksAPI {

    /* getCoinDetails
        GET /coin/{id}

        Example output:
        [
            {
                "id": "binancecoin",
                "symbol": "bnb",
                "name": "BNB",
                "image": "https://assets.coingecko.com/coins/images/825/large/bnb-icon2_2x.png?1696501970"
            }
        ]
    */
    static getCoinDetails = async (coin_id) => {
        let response = await axios.get(`${API_URL}/coin/${coin_id}`)
        const { data } = response;
        return data
    }

    /* getCoinRanks
        GET /coin/{id}/ranks?days_back=

        Example output:
        [
            {
                "coin_id": "near",
                "timestamp": 1711238400000,
                "market_cap_rank": 21,
                "market_cap": 6903928711.736258,
                "price": 6.643301763055092
            },
            {
                "coin_id": "near",
                "timestamp": 1711324800000,
                "market_cap_rank": 21,
                "market_cap": 7249259466.297204,
                "price": 6.96338581399405
            },
        ]
    */
    static getCoinRanks = async (coin_id, daysBack) => {
        let response = await axios.get(`${API_URL}/coin/${coin_id}/ranks/?days_back=${daysBack}`)
        const { data } = response;
        return data
    }

    /* getCoinsRanks
        GET /coins/ranks?days_back=

        Example output:
        [
            {
                "key": "bitcoin",
                "market_cap_rank": 1,
                "market_cap": 1386212519848,
                "symbol": "btc",
                "name": "Bitcoin",
                "image": "https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1696501400",
                "price": 70557.66959443386,
                "delta": 0
            },
            {
                "key": "ethereum",
                "market_cap_rank": 2,
                "market_cap": 435201652061,
                "symbol": "eth",
                "name": "Ethereum",
                "image": "https://assets.coingecko.com/coins/images/279/large/ethereum.png?1696501628",
                "price": 3627.104543285679,
                "delta": 0
            },
        ]
    */
    static getCoinsRanks = async (daysBack) => {
        let response = await axios.get(`${API_URL}/coins/ranks?days_back=${daysBack}`)
        const { data } = response;
        return data
    }
}

export default CoinRanksAPI