import React from 'react'

import PropTypes from 'prop-types'
import { Tag } from 'antd'
import { Table } from 'ant-table-extensions'
import { Link } from "react-router-dom"
import { priceFormatter } from '../../../utils/formatters.js'
import { useData } from '../../../data/DataProvider.js'
 
export default function RankShiftTable(props) {

  const { currentPage, setCurrentPage } = useData();

  function onChangeTable(pagination) {
	  setCurrentPage(pagination.current)
  }

  function searchFunction(dataSource, searchTerm) {
    return (dataSource ?? []).filter((row) => {
      // Check if the row contains the search term in name or symbol
      return (
        (row.name && row.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (row.symbol && row.symbol.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    });
  }

	return (
    <Table
      // bordered
      size='large'
      columns={columns(props.daysBack)}
      dataSource={props.dataSource}
      pagination={{
        current: currentPage,
        position: ['bottomLeft'],
        pageSize: 100,
        showSizeChanger: false
      }}
      searchable
      searchableProps={{
        searchFunction: searchFunction,
      }}
      onChange={onChangeTable}
      {...props}
    />
	)
}

RankShiftTable.propTypes = {
  daysBack: PropTypes.number.isRequired,
  dataSource: PropTypes.array.isRequired
}

const columns = () => {
  return [
  {
    title: 'Rank',
    dataIndex: 'market_cap_rank',
    key: 'rank',
    defaultSortOrder: 'ascend',
    render: (value) => (
      // setting vertical margins here to increase table row height (for all)
      <span style={{display: 'flex', marginBottom: 5, marginTop: 5}}>{value}</span>
    )
  },
  {
    title: `Change`,
    dataIndex: 'delta',
    key: 'delta',
    defaultSortOrder: 'descend',
    // sorter: (a, b) => a.delta - b.delta,
    render: (value) => {

      let color = 'lightGray'
      if (value > 0) { color = '#53b987'; value=`+${value}` }
      if (value < 0) { color = '#eb4d5c' }

      return (
        <Tag color={color}>
          {value}
        </Tag>
      )
    }
  },  
  {
    title: 'Coin',
    dataIndex: 'name',
    key: 'name',
    render: (value, record) => (
      <Link
        to={`/coin/${record.key}`}
        style={{
          textDecoration: 'none',
          color: 'inherit',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <img
          src={record.image}
          width={24}
          style={{ marginRight: 10 }}
          alt="coin_logo"
          loading="lazy"
          decoding="async"
          fetchpriority="low"
        />
        <div>
          <span style={{fontSize: 14, fontWeight: 'bold', marginRight: 8}}>{value}</span>
          <span style={{fontSize: 12}}>{record.symbol.toUpperCase()}</span>
        </div>
      </Link>
    )
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    render: (value) => `${priceFormatter(value)}`
  },
  {
    title: 'Market Cap',
    dataIndex: 'market_cap',
    key: 'market_cap',
    render: (value) => `${priceFormatter(value)}`
  },
  ]

}