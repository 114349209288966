/**
 * Home page
 * @module Home
 */
import React, { useState, useEffect } from 'react'
import { useData } from '../../data/DataProvider.js'
import { Row, Col } from 'antd'
import CoinRankTable from './components/CoinRankTable.js'
import CoinRanksAPI from '../../network/CoinRanksAPI.js'
import RangeSelector from '../../components/RangeSelector.js'

/**
 * Home page
 *
 * @class
 */
export default function Home() {

	const { daysBack, setDaysBack,
			previousDaysBack, setPreviousDaysBack,
			data, setData } = useData(); // Use the useData hook to access daysBack and data states

	const [loading, setLoading] = useState(false);

	const onChangeDaysBack = e => {
		setDaysBack(e.target.value); // Update daysBack state through the context
	}
  
	useEffect(() => {
		if (previousDaysBack === daysBack) {
			return
		}
		setLoading(true);
		CoinRanksAPI.getCoinsRanks(daysBack).then(output => {
			setData(output); // Update data state through the context
			setLoading(false);
			setPreviousDaysBack(daysBack)
		})
	}, [daysBack, setData, previousDaysBack, setPreviousDaysBack]);

	// React render
	return (
		<div className="site-page">
		<Row justify="center" >
			<Col>
				<RangeSelector
					style={{display: 'flex', justifyContent: 'center'}}
					onChange={onChangeDaysBack}
					value={daysBack}
				/>
			</Col>
		</Row>
		<Row justify="center" style={{overflow:"auto", 'whitespace':"nowrap", marginTop: 15}}>
			<Col>
				<CoinRankTable
					loading={loading}
					daysBack={daysBack}
					dataSource={data}
				/>
			</Col>
		</Row>
		</div>
	)
}
