/**
 * CoinDetails
 * @module layouts/CoinDetails
 */
import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { Row, Col, Spin, Space } from 'antd'
import { useData } from '../../data/DataProvider.js'
import { useParams } from 'react-router-dom'
import CoinRanksAPI from '../../network/CoinRanksAPI.js'
import CoinChart from './components/CoinChart.js';
import RangeSelector from '../../components/RangeSelector.js'
import backArrow from '../../assets/arrow.backward.svg'
import './index.css'

export default function CoinDetails() {

    let { id } = useParams();
    const { daysBack, setDaysBack } = useData(); // Use the useData hook to access detailsDaysBack and data states
    const [data, setData] = useState([]);
    const [coin, setCoin] = useState(null);
    const [loading, setLoading] = useState(false);

    const onChangeDaysBack = e => {
        setDaysBack(e.target.value); // Update daysBack state through the context
    }

    useEffect(() => {
        CoinRanksAPI.getCoinDetails(id).then(coin => {
            setCoin(coin)
        })
    }, [id, setCoin]);

    useEffect(() => {
        setLoading(true)
        CoinRanksAPI.getCoinRanks(id, daysBack).then(output => {
            setData(output)
            setLoading(false)
        })
    }, [daysBack, id, setData]);


    // React render
    return (
        <div className="site-page">

        {/* Back button */}
        <Row justify="left">
            <Col>
                <Link to="/">
                    <Space size={4}>
                        <img src={backArrow} alt='back-button'/>
                        <span className='back-button-text'>
                        View all coins
                        </span>
                    </Space>
                </Link>
            </Col>
        </Row>

        {/* Title */}
        {coin &&
        <Row justify="center" style={{marginTop: 20}}>
            <Col xs={22} sm={20} md={16}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img width={30} src={coin.image} alt={`${coin.name} logo`}></img>
                    <div style={{marginLeft: 10}}>
                        <span className='coin-name'>{coin.name}</span>
                    </div>
                    <div style={{marginLeft: 10}}>
                        <span className='coin-symbol'>{coin.symbol?.toUpperCase()}</span>
                    </div>
                </div>
            </Col>
        </Row>
        }

        {/* Details */}
        {/* <Row justify="center" style={{marginTop: 20}}>
            <Col xs={22} sm={20} md={16}>
                <div>
                    <span>
                        Symbol: <b>{coin.symbol?.toUpperCase()}</b>
                    </span>
                </div>
            </Col>
        </Row> */}

        {/* Range Selector */}
        <Row justify="center" style={{marginTop: 20}}>
			<Col xs={22} sm={20} md={16}>
                <RangeSelector
                    style={{display: 'flex', justifyContent: 'center'}}
                    onChange={onChangeDaysBack}
                    value={daysBack}
                />
			</Col>
        </Row>

        {/* Coin's Rank History Chart */}
        <Spin spinning={loading}>
        <Row justify="center" style={{marginTop: 15}}>
            <Col xs={22} sm={20} md={16}>
                <CoinChart
                    data={data}
                    daysBack={daysBack}
                />
            </Col>
        </Row>
        </Spin>
        </div>
    )
}
