import React from 'react';
import { createRoot } from 'react-dom/client';
import { IS_PROD } from './config/config.js';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes } from './routes.js'

const router = createBrowserRouter(routes);

const routerProvider = (
    <RouterProvider router={router} />
)

// wrap with strict mode only in prod
const strictMode = (content) => {
  return (
    <React.StrictMode>
      {content}
    </React.StrictMode>
  )
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(IS_PROD ? strictMode(routerProvider) : routerProvider)
