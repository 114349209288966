import React from 'react'
import { Layout, Row, Col, Grid } from 'antd'
import { Link } from 'react-router-dom'
import './Header.css'

const { useBreakpoint } = Grid;

export default function Header(props) {

	const screens = useBreakpoint()
	const title = "Coin-Ranks"
	const slogan = "Where shift happens"

	return (
		<Layout.Header
			style={{ height: 'auto',
					lineHeight: 'normal',
					backgroundColor: '#101025'//'#F0F0F3' //
					}}>
			<Row justify="center">
				<Link to="/" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
					{/* <img src={logo192} */}
					<img src={process.env.PUBLIC_URL + '/logo192.png'}
						style={{marginRight: 10}}
						width={logoSize(screens)}
						height={logoSize(screens)}
						id="logo"
						alt="coin-ranks logo"
					/>
					<pre style={{
						fontFamily: 'Inter',
						fontSize: titleFontSize(screens),
						fontWeight: 600,
						overflow: 'hidden',
						color: 'white', //'#3451b2',
						textAlign: 'center',
						whiteSpace: 'no-wrap',
						margin: 0
						}}>
						{title}
					</pre>
				</Link>
			</Row>
			<Row justify="center">
				<Col>
					<pre style={{
						// flex: 1,
						fontFamily: 'Inter',
						fontSize: sloganFontSize(screens),
						fontWeight: 400,
						overflow: 'hidden',
						textAlign: 'center',
						whiteSpace: 'no-wrap',
						color: 'white', //'#404040',
						paddingBottom: 10,
						}}>
						{slogan}
					</pre>
				</Col>
			</Row>
		</Layout.Header>
	)
}

function logoSize(screens) {
	if (screens.md) {
		return 42
	}
	else if (screens.sm) {
		return 36
	}
	else {
		return 32
	}
}

function titleFontSize(screens) {
	if (screens.md) {
		return '46px'
	}
	else if (screens.sm) {
		return '40px'
	}
	else {
		return '32px'
	}
}

function sloganFontSize(screens) {
	if (screens.md) {
		return '16px'
	}
	else if (screens.sm) {
		return '15px'
	}
	else {
		return '14px'
	}
}