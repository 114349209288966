/**
 * Application main container
 * @module layouts/SiteLayout
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from 'antd';

import Header from './Header.js'
import Footer from './Footer.js'
import './main.scss'

const { Content } = Layout

export default function SiteLayout(props) {
	const { children } = props
	// React render
	return (
        <Layout className="site-layout">
            <Header />
            
            <Content className="site-content">
                {children}
            </Content>
            
            <Footer />
        </Layout>
	)
}

SiteLayout.propTypes = {
	children: PropTypes.object.isRequired
}
