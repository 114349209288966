import React from 'react';
import SiteLayout from './layouts/SiteLayout.js'
import { DataProvider } from './data/DataProvider.js';
import { Outlet, ScrollRestoration } from "react-router-dom";

function App() {
  return (
    <SiteLayout>
      <DataProvider>
        <Outlet />
        <ScrollRestoration 
          getKey={(location) => {
            return location.pathname;
          }}
        />
      </DataProvider>
    </SiteLayout>
  )
}

export default App;
