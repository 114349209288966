import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from 'recharts';
import { Grid } from 'antd'
import moment from 'moment'
import { priceFormatter } from '../../../utils/formatters.js'
import './CoinChart.css'

const { useBreakpoint } = Grid;
const CHART_LINE_COLOR = '#8884d8'

export default function CoinChart(props) {

    // TODO: use @media in css instead of this hook
    const screens = useBreakpoint();

    const renderDot = (daysBack) => {
        if (daysBack <= 30) {
            return  { strokeWidth: 1, fill: CHART_LINE_COLOR }
        }
        return false
    }

    return (
    <ResponsiveContainer width="100%" height={chartHeight(screens)}>
    <LineChart
        data={props.data}
    >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
            dataKey="timestamp"
            domain = {['auto', 'auto']}
            type="category"
            tickFormatter = {(unixTime) => moment(unixTime).format('MMM. DD')}
            interval="preserveEnd"
        />
        <YAxis
            // label={{ value: 'Rank', angle: -90, position: 'insideLeft' }}
            reversed
            datakey="market_cap_rank"
            type="number"
            domain={["auto", "auto"]}
            interval="preserveStart"
        />
        <Tooltip content={<CustomTooltip />} isAnimationActive={false}/>
        <Line dataKey="market_cap_rank" isAnimationActive={false} stroke={CHART_LINE_COLOR} strokeWidth={2} dot={renderDot(props.daysBack)}/>
    </LineChart>
    </ResponsiveContainer>
    )
}

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="tooltip-wrapper">
          <span className="tooltip-label">{`${moment(label).format('ddd DD MMM yy, HH:mm')}`}</span><br />
          <span className="tooltip-rank-label">{`Rank: `}</span>
          <span className="tooltip-rank-value">{`${payload[0].value}`}</span><br />
          <span className="tooltip-marketcap-label">{`Price: `}</span>
          <span className="tooltip-marketcap-value">{`${priceFormatter(payload[0].payload.price)}`}</span><br />
          <span className="tooltip-marketcap-label">{`Market Cap: `}</span>
          <span className="tooltip-marketcap-value">{`${priceFormatter(payload[0].payload.market_cap)}`}</span><br />
        </div>
      )
    }
    return null
}

function chartHeight(screens) {
	if (screens.lg) {
		return 450
	}
    else if (screens.md) {
		return 425
	}
	else if (screens.sm) {
		return 400
	}
	else if (screens.xs) {
		return 300
	}
}