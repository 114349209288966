
export const priceFormatter = (value) => {
    // Convert value to a number if it's not already
    const numericValue = parseFloat(value);

    // Check if the numericValue is a valid number
    if (isNaN(numericValue)) {
        // Return the original value if it's not a valid number
        return value
    }

    var decimalDigits
    if (numericValue<1) {
        decimalDigits = 10
    }
    else if (numericValue<10) {
        decimalDigits = 5
    }
    else if (numericValue<100) {
        decimalDigits = 4
    }
    else if (numericValue<1000) {
        decimalDigits = 3
    }
    else if (numericValue<1000000) {
        decimalDigits = 2
    }
    else {
        decimalDigits = 0
    }

    // Use Intl.NumberFormat with options for better internationalization support
    const formatter = new Intl.NumberFormat('en-US', { 
        style: 'currency', 
        currency: 'USD',
        minimumFractionDigits: decimalDigits,
        maximumFractionDigits: decimalDigits
    });

    // Format the value
    let formattedValue = formatter.format(numericValue);

    // Remove unnecessary zeros after the decimal point
    formattedValue = formattedValue.replace(/(\.\d+?)0+\b/, "$1");
    formattedValue = formattedValue.replace(/\.0+\b/, "");

    return formattedValue;
}